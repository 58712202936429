import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import ReactJson from "react-json-view";
import { AuthGet, AuthPost } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import { Loader } from "../Components/Loader";

function Sandbox() {
  const initialData = {
    id: "001",
    firstName: "TEMEKA",
    middleName: "RESHA",
    lastName: "ADAMS",
    dateOfBirth: "1998-01-01T00:00:00",
    addressStatus: "current",
    houseNumber: "8180",
    streetName: "BRIARWOOD",
    streetType: "ST",
    direction: "W",
    city: "STANTON",
    state: "CA",
    zipCode: "90680",
    ssnNumber: "666603693",
    income: 50000,
  };
  const [jsonData, setJsonData] = useState(JSON.stringify(initialData));
  // const [touched, setTouched] = useState(false);
  const [creditReport, setCreditReport] = useState(null);
  const [{ settingid }] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await AuthPost(
        `api/decision-engine/get-credit-report`,
        e.target[0].value
      );
      if (res.status === 200 && res.message === "Success") {
        setCreditReport(res.creditReport);
      } else {
        toast.error("Kindly Provide Valid JSON Data", { duration: 4000 });
      }
    } catch (err) {
      toast.error(err);
    } finally {
      console.log("isLoading:::", "laoding ended after finally");
    }
  };

  useEffect(() => {
    if (settingid) {
      setCreditReport(null);
    }
  }, [settingid]);
  useEffect(() => {
    setIsLoading(false);
  }, [creditReport]);

  return (
    <div className=" ">
      {isLoading && <Loader />}
      <div className="mb20 whiteBg">
        <form onSubmit={submit}>
          <div className="form-group">
            <label htmlFor="data" className="labeltxt">DATA</label>
            <textarea
              className="form-select"
              placeholder="Provide JSON Data To Pull Credit Report"
              name="data"
              id="data"
              cols="30"
              rows="10"
              value={jsonData}
              // onBlur={() => setTouched(true)}
              onChange={(e) => setJsonData(e.target.value)}
            ></textarea>
          </div>

          <div className="col-2">
            <div>
              <button type="submit" className="MainButton democratClr">
                GET
              </button>
            </div>
          </div>
        </form>
      </div>

      <div className="frameshadow mb_30 ">
        <h6>
          <strong>Credit Report Raw Data</strong>
        </h6>
        {/* <div>
          {isLoading===false && creditReport===null ? (
            <div className="loader">Loading...</div>
          ) : isLoading===true && !creditReport ? (
            <div className="loader">Please check</div>
          ) : (
            <div className="data">Done</div>
          )}
        </div> */}
        {!isLoading && creditReport ? (
          <ReactJson src={creditReport} displayDataTypes={false} />
        ) : (
          <div className="frameshadow fs16px">
            No Credit Report Found!
          </div>
        )}
      </div>
    </div>
  );
}

export default Sandbox;
