import React, { useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import ruleicon from "../../assets/images/rule.svg";
import walleticon from "../../assets/images/wallet.svg";
import offericon from "../../assets/images/offer.svg";
import termicon from "../../assets/images/term.svg";
import handicon from "../../assets/images/hand.svg";
import gradeicon from "../../assets/images/grade.svg";
import configicon from "../../assets/images/config.svg";

const Adminsidebar = ({ setToggleIcon, toggleIcon }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [log, setLog] = useState(false);
  const queryParams = useLocation();
  let params = queryParams.pathname.split("/");
  params = params[params.length - 1];
  console.log("params::: ", params);

  const logout = () => {
    navigate("/admin/login");
    sessionStorage.clear();
  };

  const showSideBar = () => {
    setShow(!show);
  };

  const hideSidebar = () => {
    setToggleIcon(false);
  };
  // useEffect(() => {
  //   setPath(loanParams)
  // }, [path])

  // let Admin = JSON.parse(sessionStorage.getItem('resuser')).maininstallerid;

  return (
    <div className="AdminSidemenu">
      {/* <div className=" sidebarmenuTitle">

        <div id="sidenavToggler " className="cursorpointer sidenavToggler">
          <i className="far fa-chevron-left" onClick={() => showSideBar()}></i>
        </div>
      </div> */}

      <div className="menuBlock ">
            {/* <div className='othertitle'> Configurations   </div> */}
            <ul onClick={hideSidebar}>
              {/* <li onClick={() => navigate("/deconfig")} className={params === 'deconfig' ? "menuActive" : "menuIcon"}>
                <div className="menuinnerIcon">
                  {" "}
                  <img src={configicon} className=" " />
                </div>
                <div> DE Config </div>
              </li> */}
              {/* {Admin?.length > 0 ? null : ( */}
              <li
                onClick={() => {
                  navigate("/ruledatabase");
                }}
                className={
                  params === "ruledatabase" ||
                  params === "pricingdatabase" ||
                  params === "offersmaping" ||
                  params === "termsmapping" ||
                  params === "sandbox"
                    ? "menuActive"
                    : "menuIcon"
                }
              >
                <div className="menuinnerIcon">
                  {" "}
                  <img src={ruleicon} className=" " />
                </div>
                <div> Rule Database </div>
              </li>
              {/* )} */}

              <li
                onClick={() => {
                  navigate("/pricingdatabase");
                }}
                className={
                  params === "pricingdatabase" ||
                  params === "offersmaping" ||
                  params === "termsmapping" ||
                  params === "sandbox"
                    ? "menuActive"
                    : "menuIcon"
                }
              >
                <div className="menuinnerIcon">
                  {" "}
                  <img src={walleticon} className=" " />
                </div>
                <div> Pricing Model Mapping</div>
              </li>

              {/* {Admin?.length > 0 ? null : ( */}
              <li
                onClick={() => {
                  navigate("/offersmaping");
                }}
                className={
                  params === "offersmaping" ||
                  params === "termsmapping" ||
                  params === "sandbox"
                    ? "menuActive"
                    : "menuIcon"
                }
              >
                <div className="menuinnerIcon">
                  {" "}
                  <img src={offericon} className=" " />
                </div>
                <div> Offers Mapping </div>
              </li>
              {/* )} */}

              <li
                onClick={() => navigate("/termsmapping")}
                className={
                  params === "termsmapping" || params === "sandbox"
                    ? "menuActive"
                    : "menuIcon"
                }
              >
                <div className="menuinnerIcon">
                  {" "}
                  <img src={termicon} className=" " />
                </div>
                <div> Terms Mapping</div>
              </li>
              <li
                onClick={() => navigate("/sandbox")}
                className={params === "sandbox" ? "menuActive" : "menuIcon"}
              >
                <div className="menuinnerIcon menuinnerIcon2">
                  {" "}
                  <img src={handicon} className=" " />
                </div>
                <div> Run in Sandbox</div>
              </li>
              <div className="othertitle"> Meta </div>

              <li
                onClick={() => navigate("/grade")}
                className={
                  params === "grade" ||
                  params === "pricingmodel" ||
                  params === "offeredproduct" ||
                  params === "productvalue" ||
                  params === "termmodel"
                    ? "menuActive"
                    : "menuIcon"
                }
              >
                <div className="menuinnerIcon">
                  {" "}
                  <img src={gradeicon} className=" " />
                </div>
                <div> Grade</div>
              </li>
              <li
                onClick={() => navigate("/pricingmodel")}
                className={
                  params === "pricingmodel" ||
                  params === "offeredproduct" ||
                  params === "productvalue" ||
                  params === "termmodel"
                    ? "menuActive"
                    : "menuIcon"
                }
              >
                <div className="menuinnerIcon">
                  {" "}
                  <img src={offericon} className=" " />
                </div>
                <div> Pricing Model</div>
              </li>
              <li
                onClick={() => navigate("/offeredproduct")}
                className={
                  params === "offeredproduct" ||
                  params === "productvalue" ||
                  params === "termmodel"
                    ? "menuActive"
                    : "menuIcon"
                }
              >
                <div className="menuinnerIcon">
                  {" "}
                  <img src={offericon} className=" " />
                </div>
                <div> Offered Product</div>
              </li>
              <li
                onClick={() => navigate("/productvalue")}
                className={
                  params === "productvalue" || params === "termmodel"
                    ? "menuActive"
                    : "menuIcon"
                }
              >
                <div className="menuinnerIcon">
                  {" "}
                  <img src={offericon} className=" " />
                </div>
                <div> Product Value</div>
              </li>
              <li
                onClick={() => navigate("/termmodel")}
                className={params === "termmodel" ? "menuActive" : "menuIcon"}
              >
                <div className="menuinnerIcon menuinnerIcon2">
                  {" "}
                  <img src={termicon} className=" " />
                </div>
                <div> Term Model</div>
              </li>
            </ul>
          </div>
      <div style={{ clear: "both" }}></div>
    </div>
  );
};

export default Adminsidebar;
