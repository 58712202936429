import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { AuthGet, AuthPost, AuthPut } from "../../common_var/httpService";
import { useStateValue } from "../../Context/StateProvider";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function ProductValue() {
  const [{ settingid,version_id, pm_id ,stateCode}] = useStateValue();
  const [productValData, setProductValData] = useState();
  const [offers, setOffers] = useState();
  const [show, setShow] = useState(false);

  const [productEdit, setProductEdit] = useState(null);
  const [productEditValue, setProductEditValue] = useState();
  const [incomeData, setIncomeData] = useState();
  const [incomeEdit, setIncomeEdit] = useState(null);
  const [incomeEditValue, setIncomeEditValue] = useState();
  const [deleteRowId, setDeleteRowId] = useState(null);

  const getProductValue = async () => {
    await AuthGet(`api/product-value/get-all-offervalue/${settingid}`)
      .then((res) => {
        if (res.status === 200) {
          setProductValData(res?.products);
          setOffers(res?.offers);
        } else {
          toast.error(res.message, { duration: 4000 });
        }
      })
      .catch((err) => toast.error(err));
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleOpen = () => {
    setShow(true);
  };

  const handleSelectEdit = (val) => {
    handleOpen();
  };

  const getIncomData = async () => {
    await AuthGet(`api/pricing-model/get-all-income/${settingid}`).then(
      (res) => {
        if (res.status === 200) {
          setIncomeData(res);
        } else {
          toast.error(res.message, { duration: 4000 });
        }
      }
    );
  };

  const productOfferValidations = Yup.object({
    offerValue: Yup.string().required("*Offer value is required"),
    offerId: Yup.string().required("*Offer name is required"),
  });

  const addProductValue = useFormik({
    initialValues: {
      offerId: "",
      offerValue: "",
      settingId: "",
    },
    validationSchema: productOfferValidations,
    onSubmit: async (values) => {
      if (values.offerId === "default") {
        toast.error("Offer Value is required", { duration: 4000 });
        return;
      }
      values.offerValue = +values.offerValue;
      values.offerId = +values.offerId;
      values.settingId = settingid;
      values.version_id = version_id;
      values.pm_id = pm_id;
      
      await AuthPost(`api/product-value/add-offervalue`, values)
        .then((res) => {
          if (res?.status === 200) {
            addProductValue.resetForm();
            getProductValue();
            toast.success("Offers Value added successfully", {
              duration: 4000,
            });
          } else {
            toast.error(res.message, { duration: 4000 });
          }
        })
        .catch((err) => toast.error(err));
    },
  });

  const editProduct = useFormik({
    initialValues: {
      offerValue: productEditValue?.offerValue
        ? productEditValue?.offerValue
        : "",
      offerId: productEditValue?.offerId ? productEditValue?.offerId : "",
    },
    enableReinitialize: true,
    validationSchema: productOfferValidations,
    onSubmit: async (values) => {
      if (values.offerId === "default") {
        toast.error("Offer Value is required", { duration: 4000 });
        return;
      }
      values.offerValue = +values.offerValue;
      values.offerId = +values.offerId;
      values.settingId = settingid;

      await AuthPut(
        `api/product-value/update-offervalue/${productEditValue?.id}`,
        values
      ).then((res) => {
        if (res?.status === 200) {
          setProductEdit(null);
          toast.success(res.message, { duration: 4000 });
          getProductValue();
        } else {
          toast.error(res.message, { duration: 4000 });
        }
      });
    },
  });

  const inactivateOffer = async (data) => {
    await AuthPut(`api/product-value/offervalue-inactive/${data?.id}`, {
      active: false,
      offerId: data?.product_id,
      settingId: settingid,
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.message, { duration: 4000 });
          getProductValue();
          handleClose();
        } else {
          toast.error(res.message);
          handleClose();
        }
      })
      .catch((err) => {
        toast.error(err);
        handleClose();
      });
  };

  const incomeValidationSchema = Yup.object({
    maxIncome: Yup.string().required("*From Score is required"),
    minIncome: Yup.string().required("*To Score is required"),
  });

  const addIncome = useFormik({
    initialValues: {
      offerId: "",
      offerValue: "",
      settingId: "",
    },
    validationSchema: incomeValidationSchema,
    onSubmit: async (values) => {
      values.maxIncome = parseInt(values.maxIncome);
      values.minIncome = parseInt(values.minIncome);
      values.settingId = settingid;

      await AuthPost(`api/pricing-model/add-income`, values).then((res) => {
        if (res?.status === 200) {
          addIncome.resetForm();
          getIncomData();
          toast.success("Income added successfully", { duration: 4000 });
        } else {
          toast.error(res.message, { duration: 4000 });
        }
      });
    },
  });

  const editIncome = useFormik({
    initialValues: {
      maxIncome: incomeEditValue?.maxIncome ? incomeEditValue?.maxIncome : "",
      minIncome: incomeEditValue?.minIncome ? incomeEditValue?.minIncome : "",
    },
    enableReinitialize: true,
    validationSchema: incomeValidationSchema,
    onSubmit: async (values) => {
      values.maxIncome = parseInt(values.maxIncome);
      values.minIncome = parseInt(values.minIncome);
      values.settingId = settingid;

      await AuthPut(
        `api/pricing-model/update-income/${incomeEditValue?.id}`,
        values
      ).then((res) => {
        if (res?.status === 200) {
          setIncomeEdit(null);
          toast.success("Income updated successfully", { duration: 4000 });
          getIncomData();
        } else {
          toast.error(res.message, { duration: 4000 });
        }
      });
    },
  });

  useEffect(() => {
    if (settingid) {
      getProductValue();
    }
  }, [settingid,stateCode]);

  return (
    <div className=" ">
      <div className="mb20 whiteBg">
        <form onSubmit={addProductValue.handleSubmit}>
          <div className="row   align-items-center">
            <div className="col-md-3">
              <div className="mobSpace_mb24">
                <label for="ContactName" className="labeltxt">Offer Name</label>
                <select
                  className="form-select"
                  name="offerId"
                  onChange={addProductValue.handleChange}
                  onBlur={addProductValue.handleBlur}
                  value={addProductValue.values.offerId}
                >
                  <option defaultValue value="default">
                    Select Offer
                  </option>
                  {productValData?.map((offers) => (
                    <option value={offers?.id}>{offers?.offer_label}</option>
                  ))}
                </select>
                {addProductValue.touched.offerId &&
                addProductValue.errors.offerId ? (
                  <span className="error_text text-danger">
                    {addProductValue.errors.offerId}
                  </span>
                ) : null}
              </div>
            </div>

            <div className="col-md-3">
              <div className="mobSpace_mb24">
                <label for="offerValue" className="labeltxt">Offer Value</label>
                <input
                  name="offerValue"
                  className="form-control"
                  placeholder="Enter Offer Value"
                  id="offerValue"
                  onChange={(e) => {
                    addProductValue.setFieldValue(
                      "offerValue",
                      e.target.value
                        .replace(/[^\d\.]/g, "")
                        .replace(/^0+/g, "")
                        .replace(/\.(\d{0,2}).*/g, ".$1")
                    );
                  }}
                  onBlur={addProductValue.handleBlur}
                  value={addProductValue.values.offerValue}
                />
                {addProductValue.touched.offerValue &&
                addProductValue.errors.offerValue ? (
                  <span className="error_text text-danger">
                    {addProductValue.errors.offerValue}
                  </span>
                ) : null}
              </div>
            </div>

            <div className="col-md-3">
              <div>
                <button type="submit" className="MainButton democratClr mt18">
                  ADD
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="row">
        <div className="col-12">
          <form name="form">
            <div className="tableResponsive">
              <table className="table mainTable">
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}>ID</th>
                    <th style={{ width: "20%" }}>Offer Name</th>
                    <th style={{ width: "20%" }}>Value</th>
                    <th style={{ width: "30%" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {offers?.length > 0 ? (
                    offers?.map((data, index) => (
                      <tr key={data.id}>
                        <td>{index + 1}</td>
                        <td>
                          {productEdit === index ? (
                            <>
                              <select
                                className="form-select"
                                name="offerId"
                                onChange={(e) => {
                                  setProductEditValue({
                                    ...productEditValue,
                                    offerId: e.target.value,
                                  });
                                }}
                                onBlur={editProduct.handleBlur}
                                value={editProduct.values.offerId}
                              >
                                {productValData?.map((offers) => (
                                  <option value={offers?.id}>
                                    {offers?.offer_label}
                                  </option>
                                ))}
                              </select>

                              {editProduct.touched.offerId &&
                              editProduct.errors.offerId ? (
                                <p className="error_text text-danger">
                                  {editProduct.errors.offerId}
                                </p>
                              ) : null}
                            </>
                          ) : (
                            <span>{data?.offer_label}</span>
                          )}
                        </td>

                        <td>
                          {productEdit === index ? (
                            <>
                              <input
                                name="offerValue"
                                className="form-control"
                                placeholder="Enter To Score"
                                id="editProductValue"
                                // onChange={(e) => {
                                //   addProductValue.setFieldValue(
                                //     "offerValue",
                                //     e.target.value
                                //       .replace(/[^\d\.]/g, "")
                                //       .replace(/^0+/g, "")
                                //       .replace(/\.(\d{0,2}).*/g, ".$1")
                                //   );
                                // }}
                                onChange={(e) => {
                                  setProductEditValue({
                                    ...productEditValue,
                                    offerValue: e.target.value
                                      .replace(/[^\d\.]/g, "")
                                      .replace(/^0+/g, "")
                                      .replace(/\.(\d{0,2}).*/g, ".$1"),
                                  });
                                }}
                                onBlur={editProduct.handleBlur}
                                value={editProduct.values.offerValue}
                              />

                              {editProduct.touched.offerValue &&
                              editProduct.errors.offerValue ? (
                                <p className="error_text text-danger">
                                  {editProduct.errors.offerValue}
                                </p>
                              ) : null}
                            </>
                          ) : (
                            <span>{data?.offer_value}</span>
                          )}
                        </td>

                        <td>
                          {productEdit === index ? (
                            <div>
                              <button
                                className="btn btn-primary"
                                type="button"
                                style={{
                                  marginRight: "10px",
                                }}
                                onClick={() => {
                                  editProduct.handleSubmit();
                                }}
                              >
                                Save
                              </button>
                              <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => {
                                  setProductEdit(null);
                                  editProduct.resetForm();
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          ) : (
                            <div>
                              <button
                                disabled
                                className="btn btn-outline-primary"
                                type="button"
                                style={{
                                  marginRight: "10px",
                                }}
                                onClick={() => {
                                  setProductEdit(index);
                                  setProductEditValue({
                                    offerValue: data.offer_value,
                                    offerId: data?.product_id,
                                    id: data.id,
                                  });
                                }}
                              >
                                <i className="fa fa-pencil-square-o"></i>
                              </button>

                              <button
                                disabled
                                className="btn btn-outline-danger"
                                onClick={() => {
                                  setShow(true);
                                  setDeleteRowId(data);
                                }}
                                type="button"
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <td colSpan="7" className="nodatarow">
                      No Offered Value Available for the Selected Provider
                    </td>
                  )}
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </div>
      <Modal
        show={show}
        onHide={() => {
          handleClose();
          // formik.resetForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Porduct Value</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete product value?</p>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
                // formik.resetForm();
              }}
            >
              No
            </Button>
            <Button type="button" onClick={() => inactivateOffer(deleteRowId)}>
              Yes
            </Button>
          </Modal.Footer>
          {/* </form> */}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ProductValue;
